<template>
    <div
        class="justify-space-between bg-white full-width"
    >
        <div
            class="flex-1 mr-10"
        >
            <h6 class="text-left color-identify under-line-identify">결제내역</h6>
            <table
                class="table th-left td-right"
            >
                <col width="160px"/>
                <col width="auto"/>
                <tbody>
                <tr>
                    <th>결제상태</th>
                    <td>{{ state_name }}</td>
                </tr>
                <tr>
                    <th>결제수단</th>
                    <td>{{ payment_type_name }}</td>
                </tr>
                <tr>
                    <th>주문번호</th>
                    <td>{{ item.transactionSeq }}</td>
                </tr>
                <tr>
                    <th>카드종류</th>
                    <td>{{ item.cardName }}</td>
                </tr>
                <tr>
                    <th>카드번호</th>
                    <td>{{ item.cardNo }}</td>
                </tr>
                <tr>
                    <th>승인번호</th>
                    <td>{{ item.approvalNo }}</td>
                </tr>
                <tr>
                    <th>할부개월</th>
                    <td>{{ item.installment === '00' ? '일시불' : item.installment }}</td>
                </tr>
                <tr>
                    <th>거래일시</th>
                    <td>{{ item.createAt }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div
            class="flex-1 mr-10"
        >
            <h6 class="text-left color-identify under-line-identify">정산정보</h6>
            <table
                class="table th-left td-right"
            >
                <col width="160px"/>
                <col width="auto"/>
                <tbody>
                <tr>
                    <th>사업자명</th>
                    <td>{{ item.shopName }}</td>
                </tr>
                <tr>
                    <th>사업자번호</th>
                    <td>{{ item.business }}</td>
                </tr>
                <tr>
                    <th>대표자명</th>
                    <td>{{ item.name }}</td>
                </tr>
                <tr>
                    <th>주소</th>
                    <td>{{ item.address }}</td>
                </tr>
                <tr>
                    <th>MID</th>
                    <td>{{ item.mid }}</td>
                </tr>
                <tr>
                    <th>결제방식</th>
                    <td>{{ manual_type_name }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentDetail'
    , props: ['item']
    , data: function () {
        return {
        }
    }
    , computed: {
        manual_type_name: function () {
            let name = ''

            for (let i = 0; i < this.codes.manual_payment_type.length; i++) {
                if (this.item.isNonVerifyPayment == this.codes.manual_payment_type[i].code) {
                    name = this.codes.manual_payment_type[i].name
                    break
                }
            }
            return name
        }
        , state_name: function () {
            let name = ''

            for (let i = 0; i < this.codes.payment_state.length; i++) {
                if (this.item.state == this.codes.payment_state[i].code) {
                    name = this.codes.payment_state[i].name
                    break
                }
            }

            return name
        }
        , payment_type_name: function () {
            let name = ''

            for (let i = 0; i < this.codes.payment_type.length; i++) {
                if (this.item.paymentType == this.codes.payment_type[i].code) {
                    name = this.codes.payment_type[i].name
                    break
                }
            }
            return name
        }
    }
}
</script>