<template>
    <div
        class="full-height pa-10-20"
    >
        <h6>{{ program.name }}</h6>

        <Search
            class="mt-10 box-ddd"
            :search="search"
            :option="search_option"
            :codes="codes"
            :user="user"

            @click="getSearch"
            @reset="reset"
            @setSearchDateType="setSearchDateType"
            @agency="setAgency"
        >

            <select
                slot="add"
                class="pa-5-10 mr-10"

                v-model="search.paymentType"
                @change="getSearch(1)"
            >
                <option value="">서비스 구분</option>
                <option
                    v-for="add in codes.payment_type"
                    :key="'status_' + add.code"
                    :value="add.code"
                >{{ add.name }}
                </option>
            </select>
            <select
                slot="add"
                class="pa-5-10 mr-10"

                v-model="search.paymentState"
                @change="getSearch(1)"
            >
                <option value="">결제 상태</option>
                <option
                    v-for="add in codes.payment_state"
                    :key="'status_' + add.code"
                    :value="add.code"
                >{{ add.name }}
                </option>
            </select>
        </Search>

        <div
            class="mt-10 box-ddd"
        >
            <div
                class="pa-10 bg-identify"
            >
                {{ search.sDate | transDate }} ~ {{ search.eDate | transDate }}
            </div>
            <div v-if="summary.length > 0" class=" bg-white justify-space-between">
                <div class="pa-20 flex-1 bg-icon-paper border-right">
                    <div class="color-identify">{{ summary[0].name }}</div>
                    <div class="mt-10 font-weight-bold size-px-16"> {{ summary[0].count | makeComma }}건 /
                        {{ summary[0].amount | makeComma }}원
                    </div>
                </div>

                <div class="pa-20 flex-1 bg-icon-paper-cancel border-right">
                    <div class="color-identify">{{ summary[1].name }}</div>
                    <div class="mt-10 font-weight-bold size-px-16"> {{ summary[1].count  | makeComma }}건 /
                        {{ summary[1].amount | makeComma }}원
                    </div>
                </div>

                <div class="pa-20 flex-1 bg-icon-calc">
                    <div class="color-identify">{{ summary[2].name }}</div>
                    <div class="mt-10 font-weight-bold size-px-16"> {{ summary[2].count  | makeComma }}건 /
                        {{ summary[2].amount | makeComma }}원
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-10 pa-10 box-ddd bg-white height-20 overflow-y-auto">
            <div class="text-right">
                <button
                    class="box mr-10 pa-4-10 size-px-12"
                    @click="toExcel"
                >
                    <v-icon small class="color-green ">mdi mdi-file-excel</v-icon>
                    <span class="vertical-middle">엑셀 다운로드</span></button>
                <select
                    class="pa-5-10 mr-10 size-px-12"
                    v-model="search.size"
                    @change="getSearch(1)"
                >
                    <option
                        v-for="cnt in codes.list_cnt"
                        :key="'cnt_' + cnt"
                        :value="cnt"
                    >{{ cnt }} 건씩 보기
                    </option>
                </select>
            </div>
            <table
                v-if="items.length > 0"
                class="mt-10 table table-even"
            >
                <colgroup>
                    <col width="50px"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="80px"/>
                    <col width="auto"/>


                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="100px"/>
                </colgroup>
                <thead>
                <tr>
                    <th>NO</th>
                    <th>총판명</th>
                    <th>결제일시</th>
                    <th>취소일시</th>
                    <th>PG사</th>

                    <th>MID</th>
                    <th>아이디</th>
                    <th>가맹점명</th>
                    <th>서비스</th>
                    <th>결제방식</th>

                    <th>결제금액</th>
                    <th>카드번호</th>
                    <th>할부</th>
                    <th>승인번호</th>
                    <th>카드사</th>

                    <th>수수료율</th>
                    <th>결제</th>
                    <th>상세</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in item_list"
                    :key="'list_' + index"
                >
                    <td>{{ item.idx }}</td>
                    <td class="text-left">{{ item.branchName }}</td>
                    <td>{{ item.paymentDate }}</td>
                    <td>{{ item.cancelDate ? item.cancelDate : '-' }}</td>
                    <td>{{ item.pgVanName ? item.pgVanName : '-' }}</td>

                    <td>{{ item.mid }}</td>
                    <td>{{ item.shopId }}</td>
                    <td class="text-left">{{ item.shopName }}</td>
                    <td>{{ item.payment_type_name }}</td>
                    <td>{{ item.verify_payment_name }}</td>

                    <td class="text-right">{{ item.amount | makeComma }}원</td>
                    <td>{{ item.cardNumber }}</td>
                    <td>{{ item.installment === '00' ? '일시불' : item.installment + '개월' }}</td>
                    <td>{{ item.approvalNo }}</td>
                    <td>{{ item.cardIssuer }}</td>

                    <td>{{ item.feeRate }}%</td>
                    <td :class="'color-' + item.state_color">{{ item.state_name }}</td>
                    <td>
                        <button
                            class="bg-identify pa-5-10 size-px-12"
                            @click="toDetail(item)"
                        >상세보기
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <div
                v-else
                class="full-height flex-column justify-center"
            >
                <div class="text-center">
                    <v-icon
                        class="size-px-48 "
                    >mdi mdi-cloud-off-outline
                    </v-icon>
                    <br/>
                    <br/>
                    <div class="font-weight-bold size-px-24">No Data</div>
                </div>
            </div>

            <Pagination
                :program="program"
                :align="'center'"
                :options="search"

                class="mt-auto"
                @click="getSearch"
            ></Pagination>

        </div>

        <Excel
            v-if="is_excel"
            :excel_data="excel_data"

            @finish="is_excel = !is_excel"
        ></Excel>


        <Modal
            :is_modal="modal_cancel"
            :option="modal_option"
            :top="true"
            :bottom="true"

            title="결제 취소"
            content="해당 결제건을 취소하시겠습니까?"
            width="360px"
            content_class="ptb-30"

            @close="close"
            @click="cancel"
            @cancel="close"
        >
        </Modal>

        <Modal
            :is_modal="is_modal"
            :option="modal_option"
            :top="true"
            :bottom="true"
            :slot_bottom="true"

            title="결제 상세 정보"

            @close="close"
        >
            <PaymentDetail
                slot="modal-content"
                :item="item"
            ></PaymentDetail>
            <div
                slot="modal-bottom"
                class="pa-10 justify-center"
            >
                <button
                    v-if="item.canCancel == 1"
                    @click="isCancel"
                    class="pa-10-20 bg-identify-outline mr-10"
                >결제취소
                </button>
                <button
                    class="pa-10-20 bg-identify"
                    @click="close"
                >확인
                </button>
            </div>
        </Modal>
    </div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";
import PaymentDetail from "@/view/Payment/PaymentDetail";

export default {
    name: 'PaymentList',
    components: {PaymentDetail, Modal, Excel, Search, Pagination},
    data: function () {
        return {
            user: [],
            program: {
                name: '결제 내역'
                , top: true
                , title: true
                , bottom: false
            }
            , search: {
                page: 1
                , size: 10
                , search_type: ''
                , search_value: ''
                , paymentState: ''
                , paymentType: ''
                , sDate: this.date.getSearchBaseDate('-')
                , eDate: this.date.getToday('-')
                , search_date_type: 'today'
                , distributorIdx: ''
                , branchIdx: ''
                , agencyIdx: ''
                , resellerIdx: ''
                , total_count: 0
            }
            , search_option: {
                sDate: true
                , eDate: true
                , search_type: [
                    {name: '총판명', column: 1}
                    , {name: 'PG사', column: 2}
                    , {name: 'MID', column: 3}
                    , {name: '아이디', column: 4}
                    , {name: '가맹점명', column: 5}
                    , {name: '결제금액', column: 6}
                    , {name: '카드번호 앞 4자리', column: 7}
                    , {name: '승인번호', column: 8}
                    , {name: '카드사', column: 9}
                ]
                , is_search_date_type: true
                , agency: true
            }
            , is_excel: false
            , excel_data: {
                name: '결제 내역 '
                , header: [
                    {key: 0, name: '총판명', column: 'branchName'}
                    , {key: 0, name: '결제일시', column: 'paymentDate'}
                    , {key: 0, name: '취소일시', column: 'cancelDate'}
                    , {key: 0, name: 'PG사', column: 'pgVanName'}
                    , {key: 0, name: 'MID', column: 'mid'}
                    , {key: 0, name: '아이디', column: 'shopId'}
                    , {key: 0, name: '가맹점명', column: 'shopName'}
                    , {key: 0, name: '서비스', column: 'payment_type_name'}
                    , {key: 0, name: '결제방식', column: 'isNonVerifyPayment'}
                    , {key: 0, name: '결제금액', column: 'amount'}
                    , {key: 0, name: '카드번호', column: 'cardNumber'}
                    , {key: 0, name: '할부', column: 'installment'}
                    , {key: 0, name: '승인번호', column: 'approvalNo'}
                    , {key: 0, name: '카드사', column: 'cardIssuer'}
                    , {key: 0, name: '수수료율', column: 'feeRate'}
                    , {key: 0, name: '수수료(부가세 포함)', column: 'feeAmount'}
                    , {key: 0, name: '정산금액', column: 'settlementAmount'}
                    , {key: 0, name: '정산예정일', column: 'settlementDate'}
                    , {key: 0, name: '결제', column: 'state_name'}
                ]
                , content: null
            }
            , items: []
            , item: {}
            , add_status: [
                {name: '결제상태', code: '001', value: ''}
                , {name: '결제완료', code: '002', value: '1'}
                , {name: '결제취소', code: '003', value: '2'}
            ]
            , summary: []
            , list_cnt: [10, 20, 30, 50, 100, 200]
            , is_modal: false
            , modal_cancel: false
            , modal_option: {}
            , items_agency: [
                {name: '영업단을 선택하세요'}
            ]
        }
    }
    , computed: {
        item_list: function () {
            let self = this
            return this.items.filter(function (item) {

                for (let i = 0; i < self.codes.payment_type.length; i++) {
                    if (item.paymentType == self.codes.payment_type[i].code) {
                        item.payment_type_name = self.codes.payment_type[i].name
                        break
                    }
                }
                for (let i = 0; i < self.codes.payment_state.length; i++) {
                    if (item.state == self.codes.payment_state[i].code) {
                        item.state_name = self.codes.payment_state[i].name
                        item.state_color = self.codes.payment_state[i].color
                        break
                    }
                }
                for(let i = 0; i < self.codes.manual_payment_type.length; i++){
                    if(item.isNonVerifyPayment == self.codes.manual_payment_type[i].code){
                        item.verify_payment_name = self.codes.manual_payment_type[i].name
                        break
                    }
                }
                return item
            })
        }
    }
    , methods: {
        getData: async function () {
            try {
                this.$layout.onLoading()
                if(this.search.search_type) {
                    this.search.keywordType = this.search.search_type
                } else {
                    delete this.search.keywordType
                }
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'payment'
                    , data: this.search
                })

                if (result.success) {
                    this.items = result.data.tableList.data
                    this.summary = result.data.statistics
                    this.$set(this.search, 'total_count', result.data.tableList.totalCount)
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , toDetail: async function (item) {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'payment/' + item.idx
                })

                if (result.success) {
                    this.item = result.data
                    this.is_modal = true
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , reset: function () {
            this.search = {
                page: 1
                , size: 100
                , search_type: ''
                , search_value: ''
                , paymentState: ''
                , paymentType: ''
                , sDate: this.date.getSearchBaseDate('-')
                , eDate: this.date.getToday('-')
                , search_date_type: 'today'
                , distributorIdx: this.user.distributorIdx
                , branchIdx: this.user.branchIdx
                , agencyIdx: this.user.agencyIdx
                , resellerIdx: this.user.resellerIdx
            }

            this.getData()
        }
        , getSearch: function (page) {

            if (page) {
                this.$set(this.$route.params, 'page', page)
                this.$set(this.search, 'page', page)
            }

            //this.$layout.push( { name: this.$route.name, params: this.$route.params, query: this.search })

            this.getData()
        }
        , toExcel: async function () {
            try {
                this.$layout.onLoading()
                this.$set(this.search, 'startDate', this.search.sDate)
                this.$set(this.search, 'endDate', this.search.eDate)

                let result = await this.$Axios({
                    method: 'get'
                    , url: 'payment/excel'
                    , data: this.search
                })

                if (result.success) {
                    this.excel_data.content = result.data
                    this.is_excel = true
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , setSearchDateType: function (type) {
            this.search.search_date_type = type
        }
        , close: function () {
            this.is_modal = false
            this.modal_cancel = false
        }
        , isCancel: function () {
            this.close()
            this.modal_cancel = true
        }
        , cancel: async function () {
            try {
                let url = 'payment/cancel'
                if (this.item.paymentType == this.codes.payment_type_qr) {
                    url = 'payment/qrCancel'
                }
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'post'
                    , url: url
                    , data: {
                        paymentTransactionIdx: this.item.paymentTransactionIdx
                    }
                })

                if (result.success) {
                    this.$layout.setNotify( {type: 'success', message: result.message})
                    this.close()
                    await this.getData()
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , setAgency: function (agency) {
            this.search.branchIdx = this.user.branchIdx ? this.user.branchIdx : agency.branchList
            this.search.distributorIdx = this.user.distributorIdx ? this.user.distributorIdx : agency.distributorList
            this.search.agencyIdx = this.user.agencyIdx ? this.user.agencyIdx : agency.agencyList
            this.search.resellerIdx = this.user.resellerIdx ? this.user.resellerIdx : agency.resellerList
            this.getSearch(this.search.page)
        }
    }
    , created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.distributorIdx = this.user.distributorIdx
        this.branchIdx = this.user.branchIdx
        this.agencyIdx = this.user.agencyIdx
        this.resellerIdx = this.user.resellerIdx
        this.$layout.onLoad(this.program)
        this.getData()

        console.log(this.$route.params)
    }
    , watch: {
        'search.search_date_type': {
            handler: function (call) {
                let today = this.date.getToday('-')
                this.search.eDate = today
                switch (call) {
                    case 'today':
                        this.search.sDate = today

                        this.getData()
                        break
                    case 'weekly':
                        this.search.sDate = this.date.getLastDate(today, 7, '-')

                        this.getData()
                        break
                    case 'month':
                        this.search.sDate = this.date.getLastDate(today, 30, '-')

                        this.getData()
                        break
                    case '3':
                        this.search.sDate = this.date.getLastDate(today, 90, '-')
                        break
                    case '6':
                        this.search.sDate = this.date.getLastDate(today, 180, '-')
                        break
                }
            }
        }
    }
}
</script>

<style>
td {
    word-break: break-all;
}
.bg-icon-paper {
    background: url('../../assets/images/icon/icon-paper.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-paper-cancel {
    background: url('../../assets/images/icon/icon-paper-cancel.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-calc {
    background: url('../../assets/images/icon/icon-calc.svg') no-repeat 10px center;
    padding-left: 70px;
}
</style>